import $ from 'jquery'

function getCurrentPage () {
  return $('.pagination__item.active').data('page')
}

function displayPage () {
  $('.layout-4__gallery > a').each(function (index, element) {
    if (($(element).data('page') === getCurrentPage())) {
      $(element).css({'display': 'inline-block'})
    } else {
      $(element).css({'display': 'none'})
    }
  })
}

export default function init () {
  $(document).ready(function () {
    displayPage()

    $(document).on('click', '.pagination__item', function (e) {
      e.preventDefault()
      let newPageID = $(this).data('page')
      console.log(newPageID)

      if (!$(this).hasClass('active')) {
        $('.pagination__item').each(function (index, element) {
          $(element).removeClass('active')

          if ($(element).data('page') === newPageID) {
            $(element).addClass('active')
          }
        })
        // $(this).addClass('active')
        displayPage()
      }
    })
  })
}
