import $ from 'jquery'

export default function init () {
  $(document).on('click', 'a[data-to-top]', function (e) {
    e.preventDefault()
    $('html,body').animate({
      scrollTop: 0
    }, 600)
  })
}
